<template>
    <Logo :backKey="false" />
    
    <TopTools :link="true" :lang="true" />
    
    <router-view />

    <BottomTools />
</template>

<script>
import Logo from "@/components/logo/Logo.vue";
import TopTools from "@/components/tool/TopTools.vue";
import BottomTools from "@/components/tool/BottomTools.vue";

export default {
    components: {
        Logo,
        TopTools,
        BottomTools
    }
}
</script>